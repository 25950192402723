<template>

  <BaseModal
      name="modal_integration_smart_notas"
      :title="title.header "
  >
    <b-row class="ml-1 mt-3">
      <p class="title-item-2 mb-0">01.</p>
      <p class="text-description mb-0 ml-3">
        {{ $t('seller.note_center.text_2557') }} SmartNotas
      </p>
    </b-row>
    <b-row class="ml-1 mt-3 pr-3" v-if="api_key">
        <b-form-input
            id="api_key"
            v-model="api_key"
            type="text"
            @click="copyValue(api_key, 'api_key')"
        ></b-form-input>
    </b-row>

    <b-row v-else>
      <b-col cols="12 mt-3">
        <BaseButton variant="primary" @click="generateKey">{{ $t('seller.note_center.text_2547') }}</BaseButton>
      </b-col>
    </b-row>

    <div>
      <b-row class="ml-1 mt-3">
        <p class="title-item-2 mb-0">02.</p>
        <p class="text-description mb-0 ml-3">
          {{ $t('seller.note_center.text_2558') }} SmartNotas
        </p>
      </b-row>
      <b-row class="ml-1 mt-3 pr-3">
        <b-form-input
            v-validate="{ url: { require_protocol: true } }"
            name="url_webhook"
            id="url_webhook"
            placeholder="https://plataforma.com/api"
            type="text"
            v-model="urlIntegration"
            autocomplete="off"
        />
        <b-form-invalid-feedback :state="!errors.has('url_webhook')">
          {{ $t('seller.note_center.text_2559') }}
        </b-form-invalid-feedback>
      </b-row>
    </div>
    <template v-slot:footer>
      <BaseButton variant="link-info" @click="returnModal"> {{ $t('seller.note_center.text_2531') }} </BaseButton>
      <BaseButton variant="black" class="ml-3" @click="onSubmit">
        {{title.btnSave}}</BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../../../BaseModal";
import TokenService from "@/services/resources/TokenService";
import NoteCenterIntegration from "@/services/resources/NoteCenterIntegration";

const service = TokenService.build();
const noteCenterIntegrationApi = NoteCenterIntegration.build();

export default {
  name: "IntegrationSmartNotas",
  data() {
   return {
     api_key: null,
     active: false,
     urlIntegration: null,
     issueIsThis: false,
     isEdit: false,
     defaultUrlSmart:'https://app.smart-notas.com/webhook-greenn/'
   }
  },
  comments:{
    BaseModal,
  },
  computed:{
    title: function(){
      if(this.isEdit){
        return {
          header:this.$t('seller.note_center.text_2548'),
          btnSave: this.$t('seller.note_center.text_2553')
        }
      }
      return {
        header: this.$t('seller.note_center.text_2534'),
        btnSave: this.$t('seller.note_center.text_2535')
      }
    }
  },
  methods:{
    onSubmit(){
      this.$validator.validate("url_webhook").then((result) => {
        if(!result || !this.urlIntegration){
          this.$bvToast.toast(this.$t('seller.note_center.text_2554'), {
            variant: "warning",
            title: "Url SmartNotas",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return false;
        }
        if(!this.urlIntegration.includes(this.defaultUrlSmart)){
          this.$bvToast.toast(this.$t('seller.note_center.text_317'), {
            variant: "warning",
            title: this.$t('seller.note_center.text_318'),
            autoHideDelay: 5000,
            appendToast: true,
          });
          return;
        }
        noteCenterIntegrationApi.post('', {
          name: 'SmartNotas',
          config_integration: {url: this.urlIntegration},
          url: this.urlIntegration,
          'default': false
        }).then(()=>{
          this.messageSuccess(this.$t('seller.note_center.text_2560'), this.$t('seller.note_center.text_2538'))
          this.$bvModal.hide("modal_integration_smart_notas")
        })
      });
    },
    generateKey() {
      service
          .create()
          .then((response) => {
            this.api_key = response.api_key;
            this.$bvToast.toast(this.$t('seller.note_center.text_2539'), {
              title: this.$t('seller.note_center.text_2540'),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
          })
          .catch((err) => {
            console.log(err);
          });
    },
    returnModal(){
      this.$bvModal.show("modal_add_integration");
      this.$bvModal.hide("modal_integration_smart_notas")
    },
    getToken(){
      service.get('')
          .then(response=>{
            this.api_key = response.api_key
          });
    },
    copyValue(text, type) {
      const el = document.getElementById(type);
      el.select();
      document.execCommand("copy");
      this.messageSuccess(this.$t('seller.note_center.text_2541'), this.$t('seller.note_center.text_2542'))
      this.active = true
    },
    changeConfig(value){
      this.issueIsThis = value
    },
    messageSuccess(msg, title){
      this.$bvToast.toast(msg, {
        variant: "success",
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
      });
    }
  },
  created() {
    this.$root.$on('bv::modal::shown', () => {
      this.getToken()
      noteCenterIntegrationApi.search({
        url:'/search/name/SmartNotas',
        name: 'SmartNotas'
      }).then(response=>{
        if(response.id){
          this.active = true
          this.isEdit = true
          this.urlIntegration = JSON.parse(response.config_integration).url
        }
      })
    })
  }

}
</script>

<style scoped>

.title-item{
  color: #2133D2;
}
.title-item-2{
  color: #2133D2;
}
.custom-width-card{
  width: 154px;
}

.text-description{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #81858E;
}

</style>