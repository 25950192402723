import { render, staticRenderFns } from "./AddIntegration.vue?vue&type=template&id=28b7fd2a&scoped=true"
import script from "./AddIntegration.vue?vue&type=script&lang=js"
export * from "./AddIntegration.vue?vue&type=script&lang=js"
import style0 from "./AddIntegration.vue?vue&type=style&index=0&id=28b7fd2a&prod&scoped=true&lang=scss"
import style1 from "./AddIntegration.vue?vue&type=style&index=1&id=28b7fd2a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28b7fd2a",
  null
  
)

export default component.exports