import Rest from '@/services/Rest';

/**
 */
export default class NoteCenter extends Rest {
  /**
   * @type {String}
   */
  static resource = '/fiscal-center/'
}
