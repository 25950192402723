import Cookies from 'js-cookie';
import store from '@/store/index'

export default class Permissions {
  _permissions = null;

  constructor() {
    this.init()
  }

  get permissions() {
    return this._permissions;
  }

  set permissions(permissions) {
    this._permissions = permissions;
  }

  static init() {
    if(Cookies.get('setPanelPermission')) {
      this.permissions = JSON.parse(Cookies.get('setPanelPermission'))
    }
  }

  /**
   * Consult panel permissions
   * @params {string} Permission - The permission name that you want queried
   */
  static check(permission) {
    this.init();
    return this.permissions.find((x) => x.name === permission);
  }

  static hasPermission(permission) {
    if (store.getters.setPanel) {
      if (!this.check(permission)) {
        return false;
      }
    }
    return true;
  }
}
