<template>
  <div class="sigame-card">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <img src="@/assets/img/icons/sigameDigitalHorizontal.png" alt="" width="80">
      <img src="@/assets/img/icons/arrow-looped.svg" alt="" width="55">
      <img src="@/assets/img/icons/popular.svg" alt="" width="81">
    </div>
    <div>
      <h5 class="sigame-title">Sigame</h5>
    </div>
    <hr class="my-4">
    <div class="d-flex mb-4">
      <img src="@/assets/img/icons/little-star.svg" alt="" width="15" class="mr-2">
      <span class="sigame-description">20 {{ $t('seller.note_center.text_319') }}</span>
    </div>
    <BaseButton class="sigame-button mb-5" :disabled="!sigameIntegration.active" @click="$emit('connect')">
      {{ $t('seller.note_center.text_320') }}
    </BaseButton>
    <div class="sigame-selo">
      <img src="@/assets/img/icons/20-notas-selo.svg" alt="" width="190" class="mr-2">
    </div>
  </div>
</template>

<script>
export default {
  name: 'SigameDigital',
  props: ['integrations'],
  computed: {
    sigameIntegration() {
      if(!this.integrations || !this.integrations.length) return {name:'', active:false}
      return this.integrations.find(x => x.name === 'SigameDigital')
    }
  }
}
</script>

<style scoped lang="scss">
.sigame-card {
  border: solid 1px $base-color;
  border-radius: 5px;
  padding: 16px;
}
.sigame-title {
 font-weight: 700;
 font-size: 18px;
}
.sigame-description {
  font-size: 16px;
  font-weight: 600;
}
.sigame-button {
  width: 100%;
}
.sigame-selo {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>