<template>

  <BaseModal
      name="modal_integration_enotas"
      :title="title.header"
  >
    <b-row class="ml-1 mt-3">
      <p class="title-item-2 mb-0">01.</p>
      <p class="text-description mb-0 ml-3">
        {{ $t('seller.note_center.text_2529') }}
      </p>
    </b-row>
    <b-row class="ml-1 mt-3 pr-3" v-if="api_key">
      <b-form-input
          id="token"
          v-model="tokenIntegration"
          type="text"
      ></b-form-input>
    </b-row>
    <b-row class="ml-1 mt-5" v-if="api_key">
      <p class="title-item-2 mb-0">02.</p>
      <p class="text-description mb-0 ml-3">
        {{ $t('seller.note_center.send_mail_to_client') }}
      </p>
    </b-row>
    <b-row class="ml-1 mt-0 pr-3" v-if="api_key">
      <b-form-checkbox 
        size="lg" 
        placeholder="asdasd" 
        switch 
        v-model="enviarNFeClientes"
      >
        <p class="info-checkbox">
          {{ $t('seller.note_center.receive_nf_by_mail') }}
        </p>
      </b-form-checkbox>
    </b-row>
    <b-row
        v-else
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">{{ $t('seller.note_center.text_2530') }}</p>
      </b-row>
   
    <template v-slot:footer>
      <BaseButton variant="link-info" @click="returnModal"> {{ $t('seller.note_center.text_2531') }} </BaseButton>
      <BaseButton variant="black" class="ml-3" @click="onSubmit" :disabled="!activeField">
        {{title.btnSave}}</BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../../../BaseModal";
import TokenService from "@/services/resources/TokenService";
import NoteCenterIntegration from "@/services/resources/NoteCenterIntegration";

const service = TokenService.build();
const noteCenterIntegrationApi = NoteCenterIntegration.build();

export default {
  name: "IntegrationEnotas",
  data() {
   return {
     api_key: null,
     active: false,
     tokenIntegration: '',
     issueIsThis: false,
     isEdit: false,
     enviarNFeClientes: false,
   }
  },
  comments:{
    BaseModal,
  },
  computed:{
    title: function(){
      if(this.isEdit){
        return {
          header:this.$t('seller.note_center.text_2532'),
          btnSave: this.$t('seller.note_center.text_2533')
        }
      }
      return {
        header: this.$t('seller.note_center.text_2534'),
        btnSave: this.$t('seller.note_center.text_2535')
      }
    },
    activeField: function(){
      if (this.tokenIntegration && this.tokenIntegration.length) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods:{
    setActive(val) {
      this.active = val;
    },
    onSubmit(){
        if(!this.tokenIntegration){
          this.$bvToast.toast(this.$t('seller.note_center.text_2536'), {
            variant: "warning",
            title: "Enotas",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return false;
        } else {
          noteCenterIntegrationApi.post('', {
            name: 'Enotas',
            config_integration: {
              token: this.tokenIntegration,
              enviarNFeClientes: this.enviarNFeClientes,
            },
            token: this.tokenIntegration,
            'default': false
          }).then(()=>{
            this.messageSuccess(this.$t('seller.note_center.text_2537'), this.$t('seller.note_center.text_2538'))
            this.$bvModal.hide("modal_integration_enotas")
          })
        }
    },
    generateKey() {
      service
          .create()
          .then((response) => {
            this.api_key = response.api_key;
            this.$bvToast.toast(this.$t('seller.note_center.text_2539'), {
              title: this.$t('seller.note_center.text_2540'),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
          })
          .catch((err) => {
            console.log(err);
          });
    },
    returnModal(){
      this.$bvModal.show("modal_add_integration");
      this.$bvModal.hide("modal_integration_enotas")
    },
    getToken(){
      service.get('')
          .then(response=>{
            this.api_key = response.api_key
          });
    },
    copyValue(text, type) {
      const el = document.getElementById(type);
      el.select();
      document.execCommand("copy");
      this.messageSuccess(this.$t('seller.note_center.text_2541'), this.$t('seller.note_center.text_2542'))
      this.active = true
    },
    changeConfig(value){
      this.issueIsThis = value
    },
    messageSuccess(msg, title){
      this.$bvToast.toast(msg, {
        variant: "success",
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
      });
    }
  },
  created() {
    this.$root.$on('bv::modal::shown', () => {
      this.getToken()
      noteCenterIntegrationApi.search({
        url:'/search/name/Enotas',
        name: 'Enotas'
      }).then(response=>{
        if(response.id){
          this.active = true
          this.isEdit = true
          this.tokenIntegration = JSON.parse(response.config_integration).token
          this.enviarNFeClientes = JSON.parse(response.config_integration).enviarNFeClientes
        }
      })
    })
  }

}
</script>

<style scoped>

.title-item{
  color: #2133D2;
}
.title-item-2{
  color: #2133D2;
}
.custom-width-card{
  width: 154px;
}

.text-description{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #81858E;
}

</style>