<template>
  <div>
    <BaseModal :size="'xl'" name="modal_add_integration" :title="$t('seller.note_center.text_2494')">
      <div class="d-flex ml-1">
        <span class="text-description mb-0">
          {{ $t('seller.note_center.text_2495') }}
        </span>
      </div>
      <div>
        <b-row class="mt-4">
          <template>
            <b-col cols="12" sm="12" md="6" lg="4">
              <SigameDigital :integrations="notaIntegrationsList" @connect="openIntegrationSigameDigital"/>
            </b-col>
            <b-col cols="12" sm="12" md="6" lg="8">
              <OtherNotesIntegrations :integrations="notaIntegrationsList" @connect="openIntegration"/>
            </b-col>
          </template>
        </b-row>
      </div>
      <template v-slot:footer="{ cancel }">
        <BaseButton variant="link-info" @click="cancel">
          {{ $t('seller.note_center.text_2496') }}
        </BaseButton>
      </template>
    </BaseModal>
    <IntegrationSmartNotas/>
    <IntegrationNotaZZ />
    <IntegrationENotas />
    <IntegrationSigameDigital />
  </div>
</template>

<script>
import BaseModal from '../../../BaseModal'
import IntegrationNotaZZ from './IntegrationNotaZZ'
import IntegrationSmartNotas from './IntegrationSmartNotas'
import IntegrationENotas from './IntegrationENotas'
import IntegrationSigameDigital from './IntegrationSigameDigital'
import SigameDigital from './SigameDigital'
import OtherNotesIntegrations from './OtherNotesIntegrations'
import { mapGetters } from 'vuex'
import hasFlag from '@/utils/validateFlag.js'
import gtagSend from '@/utils/gtagSend';

import NoteCenter from '@/services/resources/NoteCenter'

const apiNoteCenter = NoteCenter.build()

export default {
  name: 'AddIntegration',
  components: {
    IntegrationSmartNotas,
    IntegrationNotaZZ,
    BaseModal,
    IntegrationENotas,
    IntegrationSigameDigital,
    SigameDigital,
    OtherNotesIntegrations
  },
  data() {
    return {
      dataServices: []
    }
  },
  computed: {
    ...mapGetters({
      flags: 'getFlags'
    }),
    hasSigameDigital() {
      return hasFlag('sigame_digital')
    },
    notaIntegrationsList() {
      let list = this.dataServices
      // Enotas validation
      const enotas = list.find(x => x.name === 'Enotas')
      const indexEnotas = list.indexOf(enotas)
      if(!hasFlag('enotas') && enotas && indexEnotas) {
        list.splice(indexEnotas, 1)
      }
      // Ordenar para deixar o SigameDigital no início da lista
      list.sort((a, b) => {
        if (a.name === "SigameDigital") return -1;
        if (b.name === "SigameDigital") return 1;
        if (a.name === "Enotas") return 1;
        if (b.name === "Enotas") return -1;
        return a.name.localeCompare(b.name);
      });

      return list
    }
  },
  async created() {
    this.$bvModal.show("modal_add_integration");
    if(!this.flags.length) {
      await this.$store.dispatch('fetchFlags')
    }
    await this.services()
  },
  methods:{
    async services(){
      await apiNoteCenter.get('/service')
        .then(response => {
          this.dataServices = response
        })
    },
    async sendGtag(name){
      gtagSend('integracao_nota_fiscal_adicionada', {
            ID_Cliente: JSON.parse(localStorage.user)?.id || this.$store.getters?.getProfile?.id,
            nome_da_plataforma: name,
            ID_produto: this.$store?.getters?.productId ,
            titulo_do_produto:this.$store?.getters?.productName ,
        });
    },
    openIntegration(integration) {
      switch (integration) {
        case 'SmartNotas':
          this.openIntegrationSmartNotas()
          break;
        case 'NotaZZ':
          this.openIntegrationNotaZZ()
          break;
        case 'Enotas':
          this.openIntegrationEnotas()
          break;
      }
    },
    openIntegrationSmartNotas() {
      this.sendGtag("SmartNotas");
      this.$bvModal.hide("modal_add_integration")
      this.$bvModal.show("modal_integration_smart_notas")
    },
    openIntegrationNotaZZ() {
      this.sendGtag('NotaZZ');
      this.$bvModal.hide("modal_add_integration")
      this.$bvModal.show("modal_integration_notaZZ")
    },
    openIntegrationEnotas() {
      this.sendGtag('Enotas');
      this.$bvModal.hide("modal_add_integration")
      this.$bvModal.show("modal_integration_enotas")
    },
    openIntegrationSigameDigital() {
      this.sendGtag('SigameDigital');
      this.$bvModal.hide("modal_add_integration")
      this.$bvModal.show("modal_integration_sigame_digital")
    }
  },
}
</script>

<style scoped lang="scss">

.title-item{
  color: #2133D2;
}

.text-description{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.2px;
  color: #81858E;
}

.custom-card-notes {
  cursor: pointer;
  border: solid 1px #F1F2F3;
  border-radius: 5px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 18px;
  width: 154px;
  min-height: 103px
}

.custom-section-integration {
  border: solid 1px #F1F2F3;
  border-radius: 5px;
  padding: 16px;
}

.h-full {
  height: 100%;
}

.sigame-option {
  background-color: $base-color-light;
  border: solid 1px $base-color;
}
.sigmae-description {
  color: $base-color;
}
.sigame-card {
  background-color: white;
  border-color: $base-color;
}
</style>

<style>
#modal_add_integration___BV_modal_content_ {
  margin-top: 85px;
}
#modal_add_integration___BV_modal_title_ {
  font-size: 20px;
}
</style>