<template>
  <BaseModal
      name="modal_legal_person_without_docs"
      title=""
  >
    <div class="sem-docs">
      <p>{{ $t('seller.note_center.text_2561') }}</p>
      <p>
        {{ $t('seller.note_center.text_2562') }}
      </p>
      <BaseButton variant="black" @click="goAccount"
      >{{ $t('seller.note_center.text_2563') }}</BaseButton>
    </div>
    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-3"  @click="cancel"> {{ $t('seller.note_center.text_2531') }} </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../../../BaseModal";

export default {
  name: "LegalPersonValidationDocs",
  comments:{
    BaseModal,
  },
  methods:{
    goAccount() {
      this.$router.push("/minha-conta");
    },
  }
}
</script>

<style scoped>

</style>