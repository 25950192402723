import { render, staticRenderFns } from "./NoteCenterPreview.vue?vue&type=template&id=065a3563&scoped=true"
import script from "./NoteCenterPreview.vue?vue&type=script&lang=js"
export * from "./NoteCenterPreview.vue?vue&type=script&lang=js"
import style0 from "./NoteCenterPreview.vue?vue&type=style&index=0&id=065a3563&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "065a3563",
  null
  
)

export default component.exports