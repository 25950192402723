<template>
  <div>
    <slot name="button" :openPanel="openPanel"> </slot>
    <b-sidebar
      id="sidebar-sales"
      width="450px"
      backdrop-variant="dark"
      backdrop
      right
      shadow
      v-model="visible"
      data-anima="sidebar"
      @hidden="resetUrl"
    >
      <b-container fluid class="container-sidebar">
        <b-row class="panel text-left">
          <b-col>
            <b-overlay :show="loading">
              <b-row class="item-dados">
                <b-col class="sumary">
                  <b-row class="mb-3">
                    <!-- Number -->
                    <b-col cols="6">
                      <p class="label-campo">{{ $t('seller.note_center.text_2569') }}</p>
                      <p class="dados-campo">{{ note.note_id }}</p>
                    </b-col>
                    <!-- Status -->
                    <b-col cols="6">
                      <p class="label-campo">{{ $t('seller.note_center.text_2570') }}</p>
                      <template>
                        <p
                          class="dados-campo"
                          :class="getStatus(note.note_status).class"
                        >
                          {{ getStatus(note.note_status).text }}
                        </p>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <!-- Creation Date -->
                    <b-col cols="6 mb-3">
                      <p class="label-campo">{{ $t('seller.note_center.text_2571') }}</p>
                      <p class="dados-campo">
                        {{ note.note_created_at | date }}
                      </p>
                    </b-col>
                    <!-- Emission Date -->
                    <b-col cols="6 mb-3" v-if="note.note_emitted_at">
                      <p class="label-campo">{{ $t('seller.note_center.text_2572') }}</p>
                      <p class="dados-campo">
                        {{ note.note_emitted_at | date }}
                      </p>
                    </b-col>

                    <b-col cols="6 mb-3" v-if="note.note_issued_by_integration">
                      <p class="label-campo">{{ $t('seller.note_center.text_2573') }}</p>
                      <p class="dados-campo">
                        {{ note.note_issued_by_integration }}
                      </p>
                    </b-col>
                    <!-- Value -->
                    <b-col cols="6 mb-3">
                      <p class="label-campo">{{ $t('seller.note_center.text_2574') }}</p>
                      <p class="dados-campo">
                        R$ {{ formatMoney(+note.sale_amount) }}
                      </p>
                    </b-col>
                    <!-- Link -->
                    <b-col cols="12 mb-3" v-if="note.note_link">
                      <p class="label-campo">{{ $t('seller.note_center.text_2575') }}</p>
                      <p class="dados-campo">
                        <a :href="note.note_link" target="_blank"
                          >{{
                            note.note_link.length > maxLength
                              ? note.note_link.substr(0, maxLength) + "..."
                              : note.note_link
                          }}
                        </a>
                      </p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <div
                v-if="
                  note.note_status === 'NOT_ISSUED' ||
                  note.note_status === 'ERROR'
                "
              >
                <BaseButton
                  variant="outline-info"
                  class="btn-emit-note-external w-100"
                  @click="handleClickEmittedExternal"
                  v-if="(!$store.getters.setPanel || ($store.getters.setPanel && canEditcenterNotes))"
                >
                  {{ $t('seller.note_center.text_312') }}
                </BaseButton>
              </div>

              <div class="accordion" role="tablist">
                <!-- Error -->
                <b-card
                  v-if="note.note_status === 'ERROR'"
                  no-body
                  class="mb-2 container-card"
                >
                  <b-card-header
                    header-tag="header"
                    role="tab"
                    class="header-accordion mb-2"
                  >
                    <button
                      id="btn-accordion-0"
                      v-b-toggle.accordion-0
                      class="btn-collapse"
                    >
                      {{ $t('seller.note_center.text_2576') }}
                      <img
                        id="icon-accordion-0"
                        src="@/assets/img/icons/arrow-fill.svg"
                        alt=""
                        class="img-arrow"
                      />
                    </button>
                  </b-card-header>
                  <b-collapse
                    id="accordion-0"
                    visible
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body class="body-collapse">
                      <b-card-text>
                        <b-col class="sumary">
                          <b-col cols="12">
                            <p class="label-campo">{{ $t('seller.note_center.text_313') }}</p>
                            <p class="dados-campo">
                              {{ note.response_error.error }}
                            </p>
                          </b-col>
                          <b-col
                            v-if="note.response_error.integration"
                            cols="12"
                          >
                            <p class="label-campo">
                              {{ $t('seller.note_center.text_314') }}
                            </p>
                            <p class="dados-campo">
                              {{ note.response_error.integration }}
                            </p>
                          </b-col>
                        </b-col>
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <!-- Origin -->
                <b-card no-body class="mb-2 container-card">
                  <b-card-header
                    header-tag="header"
                    role="tab"
                    class="header-accordion mb-2"
                  >
                    <button
                      id="btn-accordion-1"
                      v-b-toggle.accordion-1
                      class="btn-collapse"
                    >
                      {{ $t('seller.note_center.text_2577') }}
                      <img
                        id="icon-accordion-1"
                        src="@/assets/img/icons/arrow-fill.svg"
                        alt=""
                        class="img-arrow"
                      />
                    </button>
                  </b-card-header>
                  <b-collapse
                    id="accordion-1"
                    visible
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body class="body-collapse">
                      <b-card-text>
                        <b-col class="sumary">
                          <b-row class="mb-3">
                            <b-col cols="12">
                              <p class="label-campo">
                                {{
                                  note.is_juridical 
                                    ? $t('seller.note_center.text_2578')
                                    : $t('seller.note_center.text_2578_1')
                                }}
                                </p>
                              <p class="dados-campo">
                                {{ note.is_juridical ? note.company_name : note.saller_name }}
                              </p>
                            </b-col>
                          </b-row>
                          <!-- Document -->
                          <b-row class="mb-3" v-if="note.saller_cpf_cnpj || note.componay_cpf_cnpj">
                            <b-col cols="12">
                              <p class="label-campo">{{ $t('seller.note_center.text_2579') }}</p>
                              <p class="dados-campo">
                                {{ note.is_juridical ? note.company_cpf_cnpj : note.saller_cpf_cnpj }}
                              </p>
                            </b-col>
                          </b-row>
                          <!-- E-mail -->
                          <b-row class="mb-3">
                            <b-col cols="12 mb-12">
                              <p class="label-campo">{{ $t('seller.note_center.text_2580') }}</p>
                              <p class="dados-campo">
                                {{ note.is_juridical ? note.company_email : note.saller_email }}
                              </p>
                            </b-col>
                          </b-row>
                          <!-- Address -->
                          <b-row class="mb-3" v-if="note.saller_street || note.componay_street">
                            <b-col cols="12 mb-12">
                              <p class="label-campo">{{ $t('seller.note_center.text_2581') }}</p>
                              <p class="dados-campo">
                                {{ note.is_juridical ? getAddress("COMPANY") : getAddress("SELLER") }}
                              </p>
                            </b-col>
                          </b-row>
                          <!-- Cellphone -->
                          <b-row class="mb-3">
                            <b-col cols="12 mb-12">
                              <p class="label-campo">{{ $t('seller.note_center.text_2582') }}</p>
                              <p class="dados-campo">
                                {{ note.is_juridical ? note.company_cellphone : note.client_cellphone }}
                              </p>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <!-- Destiny -->
                <b-card no-body class="mb-2 container-card">
                  <b-card-header
                    header-tag="header"
                    role="tab"
                    class="header-accordion mb-2"
                  >
                    <button v-b-toggle.accordion-2 class="btn-collapse">
                      {{ $t('seller.note_center.text_2583') }}
                      <img
                        id="icon-accordion-2"
                        src="@/assets/img/icons/arrow-fill.svg"
                        alt=""
                        class="img-arrow"
                      />
                    </button>
                  </b-card-header>
                  <b-collapse
                    id="accordion-2"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body class="body-collapse">
                      <b-card-text>
                        <b-col class="sumary">
                          <b-row class="mb-3">
                            <b-col cols="12">
                              <p class="label-campo">{{ $t('seller.note_center.text_2578') }}</p>
                              <p class="dados-campo">
                                {{ note.client_name }}
                              </p>
                            </b-col>
                          </b-row>
                          <b-row class="mb-3" v-if="note.client_document">
                            <b-col cols="12">
                              <p class="label-campo">{{ note.client_document_type ? note.client_document_type : 'Documento' }}</p>
                              <p class="dados-campo">
                                {{ note.client_document }}
                              </p>
                            </b-col>
                          </b-row>
                          <b-row class="mb-3">
                            <b-col cols="12 mb-12">
                              <p class="label-campo">{{ $t('seller.note_center.text_2580') }}</p>
                              <p class="dados-campo">
                                {{ note.client_email }}
                              </p>
                            </b-col>
                          </b-row>
                          <b-row class="mb-3" v-if="note.client_street">
                            <b-col cols="12 mb-12">
                              <p class="label-campo">{{ $t('seller.note_center.text_2581') }}</p>
                              <p class="dados-campo">{{ getAddress() }}</p>
                            </b-col>
                          </b-row>
                          <b-row class="mb-3">
                            <b-col cols="12 mb-12">
                              <p class="label-campo">{{ $t('seller.note_center.text_2582') }}</p>
                              <p class="dados-campo">
                                {{ note.client_cellphone }}
                              </p>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <!-- Details -->
                <b-card no-body class="mb-2 container-card">
                  <b-card-header
                    header-tag="header"
                    role="tab"
                    class="header-accordion mb-2"
                  >
                    <button v-b-toggle.accordion-3 class="btn-collapse">
                      {{ $t('seller.note_center.text_2584') }}
                      <img
                        id="icon-accordion-3"
                        src="@/assets/img/icons/arrow-fill.svg"
                        alt=""
                        class="img-arrow"
                      />
                    </button>
                  </b-card-header>
                  <b-collapse
                    id="accordion-3"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body class="body-collapse">
                      <b-card-text>
                        <b-col class="sumary">
                          <b-row class="mb-3">
                            <b-col cols="12 mb-3">
                              <p class="label-campo">ID {{ $t('seller.note_center.text_2585') }}</p>
                              <p class="dados-campo">{{ note.product_id }}</p>
                            </b-col>
                            <b-col cols="12">
                              <p class="label-campo">{{ $t('seller.note_center.text_2585') }}</p>
                              <p class="dados-campo">
                                {{ note.product_name }}
                              </p>
                            </b-col>
                          </b-row>
                          <b-row class="mb-3">
                            <b-col cols="12">
                              <p class="label-campo">{{ $t('seller.note_center.text_2574') }}</p>
                              <template>
                                <p class="dados-campo">
                                  R$ {{ formatMoney(+note.sale_amount) }}
                                </p>
                              </template>
                            </b-col>
                          </b-row>

                          <b-row class="mb-3" v-if="categoryFiscal">
                            <b-col cols="12">
                              <p class="label-campo">{{ $t('seller.note_center.text_2586') }}</p>
                              <template>
                                <p class="dados-campo">
                                  {{ categoryFiscal }}
                                </p>
                              </template>
                            </b-col>
                          </b-row>

                          <b-row class="mb-3">
                            <b-col cols="12 mb-6 mb-3">
                              <p class="label-campo">{{ $t('seller.note_center.text_2587') }}</p>
                              <p class="dados-campo">
                                {{ note.client_name }}
                              </p>
                            </b-col>
                            <b-col cols="12 mb-6">
                              <p class="label-campo">{{ $t('seller.note_center.text_2582') }}</p>
                              <p class="dados-campo">
                                {{ note.client_cellphone }}
                              </p>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>

            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </b-sidebar>
  </div>
</template>
<script>
import Money from "@/mixins/money"
import NoteCenter from "@/services/resources/NoteCenter"
import moment from "moment-timezone"
import Vue from "vue"
const apiNoteCenter = NoteCenter.build()

export default {
  props: {
    updateQueryParams: {
      type: Function,
      default: () => {}
    },
    canEditcenterNotes: {
      type: Boolean,
      default: () => false
    }
  },
  name: "NoteCenterPreview",
  mixins: [Money],
  data() {
    return {
      loading: true,
      visible: false,
      note_id: -1,
      note: { response_error: { error: "", integration: "" } }
    }
  },
  computed: {
    maxLength() {
      return this.$store.getters["sidebarState"] ? 20 : 27
    },
    categoryFiscal() {
      if (this.note.note_status === "ISSUED") {
        if (this.note.note_category_fiscal === "PRODUCT") {
          return this.$t('seller.note_center.text_2585')
        } else if (this.note.note_category_fiscal === "SERVICE") {
          return this.$t('seller.note_center.text_2589')
        }
      }
      if (this.note.product_category_fiscal === "PRODUCT") {
        return this.$t('seller.note_center.text_2585')
      } else if (this.note.product_category_fiscal === "SERVICE") {
        return this.$t('seller.note_center.text_2589')
      }
      return ""
    },
    getAddress() {
      return (type) => {
        if(type === "SELLER") {
          return `${this.note.saller_street}, ${this.note.saller_number} - ${this.note.saller_city} - ${this.note.saller_state}`
        } else if(type === "COMPANY") {
          return `${this.note.company_street}, ${this.note.company_number} - ${this.note.company_city} - ${this.note.company_state}`
        }
        return `${this.note.client_street}, ${this.note.client_number} - ${this.note.client_city} - ${this.note.client_uf}`
      }
    }
  },
  mounted() {
    this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
      const icon = document.getElementById("icon-" + collapseId)
      if (icon) {
        if (isJustShown) {
          icon.classList.add("img-arrow-rotate")
        } else {
          icon.classList.remove("img-arrow-rotate")
        }
      }
    })
  },
  methods: {
    handleClickEmittedExternal() {
      Vue.swal({
        title: this.$t('seller.note_center.text_2590'),
        text: this.$t('seller.note_center.text_2591'),
        type: "danger",
        showCancelButton: true,
        confirmButtonText: this.$t('seller.note_center.text_2592'),
        cancelButtonText: this.$t('seller.note_center.text_2593'),
        customClass: "sweet-container",
        confirmButtonClass: "button button-black mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.externalIssue()
        }
      })
    },
    externalIssue() {
      this.loading = true;
      apiNoteCenter
        .post("update/" + this.note_id, {
          status: "ISSUED_EXTERNALLY",
          emitted_at: moment().format("YYYY-MM-DD"),
        })
        .then(() => {
          this.$bvToast.toast(this.$t('seller.note_center.text_316'), {
            title: "Nota",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true
          })
          this.fetchNote()
          this.$emit("externalIssue", this.note_id)
        })
        .catch((err) => { console.error(err) })
        .finally(() => { this.loading = false })
    },
    getStatus(status) {
      let r = {}
      if (status) {
        switch (status) {
          case "ISSUED":
            r = { text: this.$t('seller.note_center.text_2594'), class: "icon-green" }
            break
          case "NOT_ISSUED":
            r = { text: this.$t('seller.note_center.text_2595'), class: "icon-warning" }
            break
          case "ISSUED_EXTERNALLY":
            r = { text: this.$t('seller.note_center.text_2596'), class: "icon-blue" }
            break
          case "ERROR": 
            r = { text: this.$t('seller.note_center.text_2576'), class: "icon-red" }
            break
          case "PROCESSING":
            r = { text: this.$t('seller.note_center.text_2597'), class: "icon-black" }
            break
        }
        return r
      } else return { text: status, class: "icon-black" }
    },
    resetUrl() {
      if (Object.values(this.$route.query).length) {
        this.$router.push({ query: {} })
      }
      this.updateQueryParams()
    },
    openPanel(note_id) {
      this.visible = true
      this.note_id = note_id
      this.fetchNote()
    },
    fetchNote() {
      this.loading = true
      apiNoteCenter
        .read("/preview/" + this.note_id)
        .then(res => {
          this.note = res
          if (this.note.response_error) {
            this.note.response_error = JSON.parse(this.note.response_error)
          }

          if(!this.note.is_juridical && this.note.company_id) {
            this.note.is_juridical = true
          }

          // se for dados de empresa e não vier do back, pega do localStorage
          if(this.note.is_juridical) {
            if(!this.note.company_id) {
              const company = JSON.parse(localStorage.getItem('user')).company
              this.note.company_name = company.name
              this.note.componay_cpf_cnpj = company.cnpj
              this.note.company_email = company.email
              this.note.company_cellphone = company.telephone
              this.note.company_street = company.street
              this.note.company_number = company.number
              this.note.company_city = company.city
              this.note.company_state = company.state
            }
          }
        })
        .catch((err) => console.error(err))
        .finally(() => { this.loading = false })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-emit-note-external {
  margin-bottom: 30px;
  width: 92%;
  height: 50px;
  background: #2133d2;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  border: 0;
}

.label-campo {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 8px;
}

.container-card {
  background-color: #f7f8f9;
  border: 0;
}

.img-arrow {
  width: 10px;
  height: 5.83px;
  border-radius: 1px;
}

.img-arrow-rotate {
  transform: rotate(-180deg);
}

.body-collapse {
  background: #f7f7f7;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px;
}

.btn-collapse {
  height: 57px;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  text-align: left;
  background: #f7f8f9;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.btn-collapse:active {
  background: #e5e5e5 !important;
  color: #000000 !important;
}

.header-accordion {
  border-radius: 5px;
  background: #f7f8f9;
  border: 0;
}

.panel {
  .sumary {
    font-size: 13px;
  }

  .product-name {
    font-size: 16px;
    font-weight: 600;
    display: block;
  }
  .product-name span {
    display: block;
    margin: 10px 0 !important;
  }
}
.container-sidebar {
  padding: 40px;
  padding-top: 20px;
}
.detalhe-item {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.detalhe-item + .detalhe-item {
  margin-top: 25px;
}
header.b-sidebar-header {
  padding: 30px !important;
  padding-bottom: 0 !important;
}
</style>